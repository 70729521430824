import { CONFIG } from '../../config';
import httpService from '../../services/http.service';
import { delay } from '../../utils/helpers';

export const categoriesAsObject = {
  cat1: {
    _id: '1',
    group: 'Доступность',
    name: 'Доступно',
    color: '#1bad03',
  },
  cat2: {
    _id: '2',
    group: 'Доступность',
    name: 'Недоступно',
    color: '#ed4543',
  },
  cat3: {
    _id: '3',
    group: 'Доступность',
    name: 'Частично',
    color: '#294DF5',
  },
  cat11: {
    _id: '11',
    group: 'Тип учреждения',
    name: 'Библиотеки',
  },
  cat12: {
    _id: '12',
    group: 'Тип учреждения',
    name: 'Музеи',
  },
  cat13: {
    _id: '13',
    group: 'Тип учреждения',
    name: 'Театры',
  },
  cat14: {
    _id: '14',
    group: 'Тип учреждения',
    name: 'Концертные организации',
  },
  cat15: {
    _id: '15',
    group: 'Тип учреждения',
    name: 'Другое',
  },
  cat21: {
    _id: '21',
    group: 'Категория ОВЗ',
    name: 'Нарушения зрения',
  },
  cat22: {
    _id: '22',
    group: 'Категория ОВЗ',
    name: 'Нарушения слуха',
  },
  cat23: {
    _id: '23',
    group: 'Категория ОВЗ',
    name: 'Нарушения опорно-двигательного аппарата',
  },
  cat24: {
    _id: '24',
    group: 'Категория ОВЗ',
    name: 'Ментальные нарушения',
  },
};

const categories = Object.keys(categoriesAsObject).map((key) => ({ ...categoriesAsObject[key] }));

// возвращает целиком все данные categories
const fetchAll = async () => {
	// if (CONFIG.IS_SERVER) {
	// 	const {data} = await httpService.get(CONFIG.API_URL + "category")
	// 	return data
	// } else {
		await delay(50);
		return categories;
	// }

};

export default {
  fetchAll,
};
