import { personsAsObject as pers } from './persons.api';
import { delay } from '../../utils/helpers';

export const locationsAsObject = {
  loc1: {
    _id: '1',
    adress: 'улица Оптиков, 22Б',
    info: 'Сквозной участок приобретён Обществом для постройки двух зданий, второе располагалось по адресу Можайская ул., д. 8. Деревянный дом, выходящий фасадом на ул. Рузовскую, перестроен в каменный по проекту архитектора Зайцева Д. Д. Здание в стиле эклектики позднее надстроено до 4 этажей. В нём располагался приют для девочек',
    image:
      'https://charity-old.lbspb.ru/system/pages/000/013/55/images/small/d34fd6d0472be9d7ccc415d86404e582df3b2fa5.jpg?1701963195',
    geo: '59.997301, 30.227003',
    dates: '1890-1898гг',
    persons: [pers.actor._id],
    links: ['Просто о важном. Издание 5. Стр.55-56'],
  },
  loc2: {
    _id: '2',
    adress: 'исторический район Колтовская Слобода',
    info: 'Сквозной участок приобретён Обществом для постройки двух зданий, второе располагалось по адресу Можайская ул., д. 8. Деревянный дом, выходящий фасадом на ул. Рузовскую, перестроен в каменный по проекту архитектора Зайцева Д. Д. Здание в стиле эклектики позднее надстроено до 4 этажей. В нём располагался приют для девочек',
    image:
      'https://charity-old.lbspb.ru/system/pages/000/013/55/images/small/d34fd6d0472be9d7ccc415d86404e582df3b2fa5.jpg?1701963195',
    geo: '59.964110, 30.280082',
    dates: '1890-1898гг',
    persons: [pers.doctor._id],
    links: ['Просто о важном. Издание 5. Стр.55-56'],
  },
  loc3: {
    _id: '3',
    adress: 'Петрозаводская улица, 13',
    info: 'Сквозной участок приобретён Обществом для постройки двух зданий, второе располагалось по адресу Можайская ул., д. 8. Деревянный дом, выходящий фасадом на ул. Рузовскую, перестроен в каменный по проекту архитектора Зайцева Д. Д. Здание в стиле эклектики позднее надстроено до 4 этажей. В нём располагался приют для девочек',
    image:
      'https://charity-old.lbspb.ru/system/pages/000/013/55/images/small/d34fd6d0472be9d7ccc415d86404e582df3b2fa5.jpg?1701963195',
    geo: '59.965231, 30.288201',
    dates: '1990-1998гг',
    alt_names: ['улица Жукова, 33', 'улица Ленина, 33'],
    persons: [pers.physics._id, pers.engineer._id],
    links: [
      'Попечительство императрицы Марии Фёдоровны о глухонемых : [историческая справка]$http://encspb.ru/object/2815811872?lc=ru',
      'Векслер А.Ф. Попечитель // Родина. 1992. № 11/12. С. 123-128$https://ya.ru',
      'Просто о важном. Издание 5. Стр.55-56',
    ],
  },
  loc4: {
    _id: '4',
    adress: 'муниципальный округ Васильевский, 13',
    info: 'Сквозной участок приобретён Обществом для постройки двух зданий, второе располагалось по адресу Можайская ул., д. 8. Деревянный дом, выходящий фасадом на ул. Рузовскую, перестроен в каменный по проекту архитектора Зайцева Д. Д. Здание в стиле эклектики позднее надстроено до 4 этажей. В нём располагался приют для девочек',
    image:
      'https://charity-old.lbspb.ru/system/pages/000/013/55/images/small/d34fd6d0472be9d7ccc415d86404e582df3b2fa5.jpg?1701963195',
    geo: '59.943705, 30.269087',
  },
  loc5: {
    _id: '5',
    adress: 'муниципальный округ Сампсониевское',
    info: 'Сквозной участок приобретён Обществом для постройки двух зданий, второе располагалось по адресу Можайская ул., д. 8. Деревянный дом, выходящий фасадом на ул. Рузовскую, перестроен в каменный по проекту архитектора Зайцева Д. Д. Здание в стиле эклектики позднее надстроено до 4 этажей. В нём располагался приют для девочек',
    image:
      'https://charity-old.lbspb.ru/system/pages/000/013/55/images/small/d34fd6d0472be9d7ccc415d86404e582df3b2fa5.jpg?1701963195',
    geo: '59.966606, 30.347563',
  },
  loc6: {
    _id: '6',
    adress: 'Сенная улица, 13',
    info: 'Сквозной участок приобретён Обществом для постройки двух зданий, второе располагалось по адресу Можайская ул., д. 8. Деревянный дом, выходящий фасадом на ул. Рузовскую, перестроен в каменный по проекту архитектора Зайцева Д. Д. Здание в стиле эклектики позднее надстроено до 4 этажей. В нём располагался приют для девочек',
    image:
      'https://charity-old.lbspb.ru/system/pages/000/013/55/images/small/d34fd6d0472be9d7ccc415d86404e582df3b2fa5.jpg?1701963195',
    geo: '59.928727, 30.310993',
  },
  loc7: {
    _id: '7',
    adress: 'Веденская улица, 13',
    info: 'Сквозной участок приобретён Обществом для постройки двух зданий, второе располагалось по адресу Можайская ул., д. 8. Деревянный дом, выходящий фасадом на ул. Рузовскую, перестроен в каменный по проекту архитектора Зайцева Д. Д. Здание в стиле эклектики позднее надстроено до 4 этажей. В нём располагался приют для девочек',
    dates: '1990-1998гг',
    alt_names: ['улица Жукова, 33'],
    persons: [pers.actor._id, pers.doctor._id],
    links: [
      'Попечительство императрицы Марии Фёдоровны о глухонемых : [историческая справка]$http://encspb.ru/object/2815811872?lc=ru',
      'Векслер А.Ф. Попечитель // Родина. 1992. № 11/12. С. 123-128$https://ya.ru',
      'Просто о важном. Издание 5. Стр.55-56',
    ],
    geo: '59.954017, 30.302296',
  },
  loc8: {
    _id: '8',
    adress: 'Чкаловская улица, 13',
    info: 'Сквозной участок приобретён Обществом для постройки двух зданий, второе располагалось по адресу Можайская ул., д. 8. Деревянный дом, выходящий фасадом на ул. Рузовскую, перестроен в каменный по проекту архитектора Зайцева Д. Д. Здание в стиле эклектики позднее надстроено до 4 этажей. В нём располагался приют для девочек',
    geo: '59.964227, 30.298540',
  },
  loc9: {
    _id: '9',
    adress: 'Крестовская улица, 13',
    info: 'Сквозной участок приобретён Обществом для постройки двух зданий, второе располагалось по адресу Можайская ул., д. 8. Деревянный дом, выходящий фасадом на ул. Рузовскую, перестроен в каменный по проекту архитектора Зайцева Д. Д. Здание в стиле эклектики позднее надстроено до 4 этажей. В нём располагался приют для девочек',
    image:
      'https://charity-old.lbspb.ru/system/pages/000/013/55/images/small/d34fd6d0472be9d7ccc415d86404e582df3b2fa5.jpg?1701963195',
    geo: '59.969083, 30.268099',
  },
  loc10: {
    _id: '10',
    adress: 'Новая деревня, 13',
    info: 'Сквозной участок приобретён Обществом для постройки двух зданий, второе располагалось по адресу Можайская ул., д. 8. Деревянный дом, выходящий фасадом на ул. Рузовскую, перестроен в каменный по проекту архитектора Зайцева Д. Д. Здание в стиле эклектики позднее надстроено до 4 этажей. В нём располагался приют для девочек',
    image:
      'https://charity-old.lbspb.ru/system/pages/000/013/55/images/small/d34fd6d0472be9d7ccc415d86404e582df3b2fa5.jpg?1701963195',
    geo: '59.985232, 30.267901',
  },
};

let locations = Object.keys(locationsAsObject).map((key) => ({ ...locationsAsObject[key] }));

// возвращает данные locations для показа на карте (_id, adress, geo)
const fetchAll = async () => {
	// if (CONFIG.IS_SERVER) {
	// 	const {data} = await httpService.get(CONFIG.API_URL + "location")
	// 	return data
	// } else {
  await delay(750);
  let newArr = [];
  // newArr = locations;
  // включить, если реализована поддержка загрузки полной информации об адресе в попапе карты
  locations.forEach((o) => newArr.push({ _id: o._id, adress: o.adress, geo: o.geo, persons: o.persons, info:o.info, image:o.image }));
  return newArr;
	// }
};

// возвращает полные данные об одной локации
const getById = async (id) => {
	// if (CONFIG.IS_SERVER) {
	// 	const {data} = await httpService.get(CONFIG.API_URL + "location/" + id)
	// 	return data
	// } else {
		await delay(50);
		return locations.find((loc) => loc._id === id);
	// }
};

// const add = async (data) => {
//   await delay(50);
//   const newItem = {
//     ...data,
//     created_at: Date.now(),
//     _id: Math.random().toString(36).substr(2, 9),
//   };
//   locations.push(newItem);
//   return newItem;
// };

// const remove = async (id) => {
//   await delay(50);
//   locations = locations.filter((x) => x._id !== id);
//   return id;
// };

export default {
  fetchAll,
  getById,
  // add,
  // remove,
};
