export function engToRus(data: string) {
	switch (data) {
		case "map":
			return "Карта"
		case "organizations":
			return "Организации"
		case "locations":
			return "Адреса"
		case "persons":
			return "Люди"
		case "about":
			return "О проекте"
	}
}
