import organizations from "./fake.api/organizations.api"
import persons from "./fake.api/persons.api"
import categories from "./fake.api/categories.api"
import locations from "./fake.api/locations.api"
const API = {
	organizations,
	persons,
	categories,
	locations
}
export default API
